.map {
  height: 600px;
  width: 100%;
  font-size: small;
  position: relative;
}
.map label {
  display: block;
  margin-right: 1em;
}

/* Map settings, legend. */
.ol-full-screen {
  position: absolute;
  top: 5px;
  right: 5px;
}
.threshold-control {
  position: absolute;
  top: 41px;
  right: 5px;
}
.threshold-control button {
  font-size-adjust: 0.7;
  font-weight: bolder;
}
.settings-control {
  position: absolute;
  top: 75px;
  right: 5px;
}
.routes-control {
  position: absolute;
  top: 109px;
  right: 5px;
}
.routes-control-has-routes {
  background-color: rgba(0,0,255,0.5);
}
.legend-control {
  position: absolute;
  top: 143px;
  right: 5px;
}
.control-panel {
  position: absolute;
  top: 75px;
  right: 5px;
  z-index: 100;
  min-width: 250px;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
}
.control-panel h4 {
  margin-top: 0px;
  margin-bottom: 0.5em;
}
.routes-panel {
  top: 109px;
}
.routes-panel .description {
  font-style: italic;
  max-width: 20em;
  clear: both;
}
.routes-panel input[type="file"] {
	font-size: inherit;
}
.routes-panel .feature-delete {
  vertical-align: baseline;
}
.routes-panel .feature-delete img {
  max-height: 1em;
}
.route-show-hide-wrapper {
  display: block;
  clear:left;
  float: left;
}
.route-name-wrapper {
  display: block;
  float: left;
  margin-left: 1em;
}
.route-remove-wrapper {
  display: block;
  float: right;
  clear: right;
  margin-left: 2em;
}
.closer {
  position: absolute;
  top: 10px;
  right: 10px;
}
#curvature-map-legend {
  top: 143px;
  z-index: 99;
}
#curvature-map-legend img {
  width: 250px;
}

.ol-geocoder.gcd-gl-container {
  top: 5px;
  left: 5px;
}
.ol-geocoder ul.gcd-gl-result {
  z-index: 200;
}
/* Zoom styling */
.ol-zoom {
  top: 41px;
  left: 5px;
}
.ol-zoom .ol-zoom-out {
  margin-top: 204px;
}
.ol-zoomslider {
  background-color: transparent;
  top: 66px;
}
.ol-touch .ol-zoom .ol-zoom-out {
  margin-top: 206px;
}
.ol-touch .ol-zoomslider {
  top: 3em;
}
.ol-zoom-in.ol-has-tooltip:hover [role=tooltip],
.ol-zoom-in.ol-has-tooltip:focus [role=tooltip] {
  top: 3px;
}
.ol-zoom-out.ol-has-tooltip:hover [role=tooltip],
.ol-zoom-out.ol-has-tooltip:focus [role=tooltip] {
  top: 232px;
}
.ol-control.ol-zoomslider:hover {
  background-color: rgba(255,255,255,.2);
}
.ol-zoomslider-thumb {
  z-index: 20;
}
.ranges-container {
  position: absolute;
  top: 65px;
  left: 5px;
  width: 2em;
  height: 200px;
  z-index: -10;
  padding: 2px;
}
.ol-touch .ranges-container {
  top: 3em;
}
.ranges {
  transform: rotate(-90deg);
  position: absolute;
  top: 92px;
  left: -87px;
  width: 200px;
  height: 19px;
  z-index: -10;
  font-size: 0.8em;
  text-decoration: none;
}
.ol-touch .ranges {
  font-size: 1em;
}
.ranges .range-details, .ranges .range-overview {
  position: absolute;
  text-align: center;
  height: 19px;
  top: 0px;
}
.ol-touch .ranges .range-details, .ol-touch .ranges .range-overview {
  height: 26px;
}
.ranges .range-details {
  width: 122px;
  right: 0px;
}
.ranges .range-overview {
  width: 78px;
  left: 0px;
}
.ranges .range-active {
  color: white;
  background-color: rgba(0,0,0,.6);
}
.ranges .range-inactive {
  color: black;
  background-color: rgba(255,255,255,.6);
}

/* Attribution separators */
.ol-attribution li:not(:last-child)::after {
  content: "; ";
}
.ol-attribution li:first-child::after {
  content: ". Base-layers: ";
}

/* FullScreen styles */
.map:-webkit-full-screen {
  height: 100%;
  margin: 0;
}
.map:-ms-fullscreen {
  height: 100%;
}
.map:fullscreen {
  height: 100%;
}
/* Full-screen icon */
button.ol-full-screen-false img {
  margin: 3px;
  max-width: 1em;
}

/* Control icons */
.legend-control img {
  margin: 3px;
  max-width: 1em;
}
.settings-control img {
  margin: 3px;
  max-width: 1em;
}
.routes-control img {
  margin: 3px;
  max-width: 1em;
}

/* Pop-up road details styles. */
.road-details dl {
  margin-bottom: 0.5em;
}
.road-details .open-on-osm {
  margin-bottom: 0.5em;
}
