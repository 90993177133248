.alert {
  text-align: center;
  padding: 5px;
  background-color: #fc0;
}

.support {
  text-align: center;
  padding: 5px;
  background-color: #fc0;
  font-size: 0.7em;
}
