.ol-popup {
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  border-radius: 10px;
  min-width: 320px;
  background-color: white;
  padding: 10px;
}
.ol-popup-closer {
  float: right;
  text-decoration: none;
  color: black;
}
.ol-popup-content {
  max-height: 100%;
  overflow-x: inherit;
  margin-top: 10px;
}

.ol-popup dl {
  max-width: 320px;
}
.ol-popup dt {
  float: left;
  width: 7em;
}
.ol-popup dd {
  margin-left: 7em;
}

.details-control:hover {
  cursor: pointer;
}
.details-control.closed::after {
  content: ' ►';
}
.details-control.open::after {
  content: ' ▼';
}
.details-content {
  text-align: center;
}

.details-content table {
  text-align: left;
  width: 510px;
}
.details-content td, .details-content tr {
  padding-left: 5px;
  padding-right: 5px;
}
.details-content td.curvature, .details-content td.length {
  text-align: right;
  white-space: nowrap;
}
.details-content td.maxspeed {
  white-space: nowrap;
}
.road-details dd .summary_value {
  white-space: nowrap;
}

/**
 * Spinner from: http://tobiasahlin.com/spinkit/
 * By: tobiasahlin
 * https://github.com/tobiasahlin/SpinKit
 */
 .spinner {
   margin: 5px auto;
   width: 50px;
   height: 50px;
   text-align: center;
   font-size: 10px;
   color:
 }

 .spinner > div {
   background-color: #aaa;
   height: 100%;
   width: 6px;
   display: inline-block;
   margin: 1px;

   -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
   animation: sk-stretchdelay 1.2s infinite ease-in-out;
 }

 .spinner .rect2 {
   -webkit-animation-delay: -1.1s;
   animation-delay: -1.1s;
 }

 .spinner .rect3 {
   -webkit-animation-delay: -1.0s;
   animation-delay: -1.0s;
 }

 .spinner .rect4 {
   -webkit-animation-delay: -0.9s;
   animation-delay: -0.9s;
 }

 .spinner .rect5 {
   -webkit-animation-delay: -0.8s;
   animation-delay: -0.8s;
 }

 @-webkit-keyframes sk-stretchdelay {
   0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
   20% { -webkit-transform: scaleY(1.0) }
 }

 @keyframes sk-stretchdelay {
   0%, 40%, 100% {
     transform: scaleY(0.4);
     -webkit-transform: scaleY(0.4);
   }  20% {
     transform: scaleY(1.0);
     -webkit-transform: scaleY(1.0);
   }
 }
